import {findVideoCategoryByShortname} from "../common/videoCategories";
import {styled, Typography} from "@mui/material";
import VideoCategoryList from "../components/VideoCategoryList";
import {Auth} from 'aws-amplify';
import {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";

const StyledFullWidthDiv = styled('div')(({theme}) => ({
    width: '100%',
    backgroundColor: theme.palette.background.default,
    color: "whitesmoke"
}));
const StyledDiv = styled('div')(({theme}) => ({
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
}));


function checkValidUser(groups: string[], videoCategory: any) {
    if (videoCategory === undefined) {
        return false;
    }
    let allowedGroups: [] = videoCategory.availability;
    return allowedGroups.some(r => groups.includes(r));
}


function VideoCategory() {
    const [isValid, setIsValid] = useState<boolean>(false);
    let {shortname} = useParams<"shortname">();

    const getUser = async () => {
        const user = await Auth.currentAuthenticatedUser();
        if (!user) {
            throw new Error("Not authenticated");
        }
        const session = user.getSignInUserSession();
        const accessToken = session.getAccessToken();
        const groups = accessToken.payload["cognito:groups"] || [];
        setIsValid(checkValidUser(groups, videoCategory));
    }

    useEffect(() => {
        getUser();
    });


    if (!shortname) {
        return NoMatch();
    }
    let videoCategory = findVideoCategoryByShortname(shortname);

    if (!videoCategory) {
        return NoMatch();
    }

    return (
        <StyledFullWidthDiv>
            <StyledDiv>
                <Typography variant='h3'>{videoCategory?.text}</Typography>
            </StyledDiv>
            <StyledDiv>
                <Typography variant='h6'>{isValid ? videoCategory?.description
                    : "Sorry, your karate belt level does not have access to view these videos"}
                </Typography>
            </StyledDiv>
            {isValid && (
                <VideoCategoryList shortname={videoCategory.shortname as string}/>
            )}
        </StyledFullWidthDiv>

    );
}

function NoMatch() {
    return (
        <div>
            <h2>Something's gone a bit wrong!</h2>
            <p>
                <Link to="/">Go to the home page</Link>
            </p>
        </div>
    );
}


export default VideoCategory;
