import VideoCategories from "./videoCategories.json"


export interface VideoCategory {
    id: number
    text: string
    shortname: string
    description: string
    availability: string[]
    image: string
    videos?: Video[]
}

export interface Video {
    id: number
    filename: string
    description: string
}

let videoCategories = VideoCategories as VideoCategory[];

function findVideoCategoryByShortname(shortname: string): VideoCategory | undefined {
    return videoCategories.find((element) => {
        return element.shortname === shortname;
    })
}

function findVideoListByShortname(shortname: string): Video[] {
    const category = findVideoCategoryByShortname(shortname);

    if (category && category.videos) {
        return category.videos;
    }
    return [];
}

export {findVideoCategoryByShortname, findVideoListByShortname, videoCategories};

