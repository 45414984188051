import {Paper, styled} from "@mui/material";
import Markdown from "./Markdown";


const StyledFullWidthDiv = styled('div')(({theme}) => ({
    width: '100%',
    backgroundColor: theme.palette.background.default,
    color: "whitesmoke"
}));
const StyledPaper = styled(Paper)(({theme}) => ({
    backgroundColor: theme.palette.background.default,
    flex: 1,
    padding: theme.spacing(3),
    color: 'whitesmoke',
    align: 'center',
    marginTop: 'auto',
    table: {
        borderColor: 'whitesmoke',
        borderWidth: '1px',
        borderSpacing: '0',
        borderStyle: 'solid'
    },
    th: {
        borderColor: 'whitesmoke',
        borderWidth: '1px',
        borderStyle: 'solid'
    },
    td: {
        borderColor: 'whitesmoke',
        borderWidth: '1px',
        borderStyle: 'solid'
    }
}));
// <style>
//     table{
//     border-collapse: collapse;
//     border-spacing: 0;
//     border:2px solid #000000;
// }
//
//     th{
//     border:2px solid #000000;
// }
//
//     td{
//     border:1px solid #000000;
// }
// </style>
function Terminology() {
    return (
        <StyledFullWidthDiv>
            <StyledPaper>
                <Markdown url={process.env.REACT_APP_CONTENT_BASE_URL + "terminology.md"}/>
            </StyledPaper>
        </StyledFullWidthDiv>
    );
}

export default Terminology;
