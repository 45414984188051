import React from 'react';
import './App.css';
import Footer from "./components/Footer";
import Header from "./components/Header";
import VideoCategory from "./pages/VideoCategory";
import Videos from "./pages/Videos";
import Home from "./pages/Home";
import {responsiveFontSizes, styled, ThemeProvider} from "@mui/material";
import VideoPlayer from "./pages/VideoPlayer";
import Syllabus from "./pages/Syllabus";
import Classes from "./pages/Classes";
import {BrowserRouter, Link, Route, Routes} from "react-router-dom";
import Logout from "./pages/Logout";
import NoPageFound from "./pages/NoPageFound";
import GojuRyuHistory from "./pages/GojuRyuHistory";
import theme from "./Theme";
import {Amplify} from "aws-amplify";
import {Authenticator} from '@aws-amplify/ui-react';
import config from "./aws-exports";
import Terminology from "./pages/Terminology";
import NewsEvents from "./pages/NewsEvents";
import FormCategories from "./pages/Forms";

// Call configuration API at the global Layout level
Amplify.configure(config);

Amplify.Logger.LOG_LEVEL = 'DEBUG';

const StyledMain = styled('main')(
    ({theme}) => `
    background-color: ${theme.palette.background.default};    
    flex: 1;
    overflow-y: auto;
    max-width: '1024px';
    height: '100%'    
  `,
);

const Content = styled('div')(
    ({theme}) => `
    // flex: 1;
    // overflow-y: auto;
    height: calc('100%');
  `,
);

const StyledApp = styled('div')(
    ({theme}) => `
    height: 100vh;
    display: flex;
    flex-direction: column;
    color: 'whitesmoke';
  `,
);

const StyledFooter = styled('footer')(
    ({theme}) => `
    height: 28px;
    color: 'whitesmoke';
    text-align: center;
  `,
);

const menuOptions = [
    {title: 'Home', url: '/', newWindow: false},
    {title: 'News/Events', url: '/newsevents', newWindow: false},
    {title: 'Classes', url: '/classes', newWindow: false},
    {title: 'History', url: '/history', newWindow: false},
    {title: 'Syllabus', url: '/syllabus', newWindow: false},
    {title: 'Terminology', url: '/terminology', newWindow: false},
    {title: 'Videos', url: '/videos', newWindow: false},
    {title: 'Forms', url: '/forms', newWindow: false},
    {title: 'Safekeeping', url: 'https://padlet.com/fcsdavies/blackrod-and-bolton-academy-of-goju-ryu-karate-do-members-li-sczvvgfv9hqih2d6', newWindow: true},
    {title: 'Logout', url: '/logout', newWindow: false}
];


function App() {
    let fontTheme = responsiveFontSizes(theme);
    return (
        <Authenticator.Provider>
            <BrowserRouter>

                <ThemeProvider theme={fontTheme}>
                    <StyledApp>
                        <Header title="Blackrod and Bolton Karate Club" sections={menuOptions}/>
                        <StyledMain>
                            <Content>
                                <Routes>
                                    <Route path="/" element={<Home/>}/>
                                    <Route path="/newsevents" element={<NewsEvents/>}/>
                                    <Route path="/classes" element={<Classes/>}/>
                                    <Route path="/history" element={<GojuRyuHistory/>}/>
                                    <Route path="/syllabus" element={<Syllabus/>}/>
                                    <Route path="/terminology" element={<Terminology/>}/>
                                    <Route path="/videos" element={<Videos/>}/>
                                    <Route path="/forms" element={<FormCategories/>}/>
                                    <Route path="/logout" element={<Logout/>}/>
                                    <Route path="/videos/:shortname" element={<VideoCategory/>}/>
                                    <Route path="/video-player/:shortname/:filename/:width/:height"
                                           element={<VideoPlayer/>}/>
                                    <Route path="*" element={<NoPageFound/>}/>

                                </Routes>
                            </Content>
                        </StyledMain>
                        <StyledFooter>
                            <Footer/>
                        </StyledFooter>
                    </StyledApp>
                </ThemeProvider>
            </BrowserRouter>
        </Authenticator.Provider>
    );
}

export default App;
