import formsList from "../common/forms.json";
import {Card, CardHeader, Grid, styled} from "@mui/material";

const StyledDiv = styled('div')(({theme}) => ({
    display: "flex",
    flexWrap: "wrap",
    height: "100%",
    backgroundColor: theme.palette.background.paper,
}));

const Section = styled('h1')(({theme}) => ({
    backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(3),
    color: 'whitesmoke',
}));

const StyledCard = styled(Card)(({theme}) => ({
    width: 250,
    backgroundColor: '#2d2f32',
    margin: 'auto',
    textAlign: 'center',
    cursor: 'pointer'
}));
const StyledGrid = styled(Grid)(({theme}) => ({}));
const StyledGridItem = styled(Grid)(({theme}) => ({
    minWidth: '270px'
}));

function FormCategories() {
  const openInNewTab = (url: string) => {
    window.open(url, "_blank", "noreferrer");
  };
    // @ts-ignore
    return (
        <StyledDiv>
            {formsList.map((section => (
            <Section>
                {section.section_title}
            <StyledGrid container spacing={1}>
                {section.forms.map((tile) => (
                    <StyledGridItem item xs={12} sm={6} md={4} lg={2} key={tile.id}
                                    onClick={() => openInNewTab(tile.url)}>
                        <StyledCard>
                            <CardHeader title={tile.title}/>
                        </StyledCard>
                    </StyledGridItem>
                ))}
            </StyledGrid>
                </Section>
                )))}
        </StyledDiv>
    );

}


export default FormCategories;
