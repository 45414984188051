import {createTheme} from "@mui/material";
import { blueGrey } from '@mui/material/colors';

const theme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            light: blueGrey[700],
            main: blueGrey[800],
            dark: blueGrey[900],
            contrastText: '#000000',
        },
        secondary: {
            light: blueGrey[700],
            main: blueGrey[800],
            dark: blueGrey[900],
            contrastText: '#000000',
        },
    },
});

export default theme;
