import {Card, CardContent, CardHeader, Grid, styled} from "@mui/material";
import React from "react";
import ClassList from "../common/classes.json";


const StyledFullWidthDiv = styled('div')(({theme}) => ({
    backgroundColor: theme.palette.background.default,
    color: "whitesmoke",
    a: {
        color: "wheat",
        hover: "silver",
        visited: "wheat"
    },
    padding: theme.spacing(1)
}));
const StyledCard = styled(Card)(({theme}) => ({
    width: 320,
    height: 320,
    minWidth: 320,
    backgroundColor: theme.palette.background.paper,
}));
const StyledGrid = styled(Grid)(({theme}) => ({
    marginBottom: 10,
    justify: 'space-around'
}));
const StyledGridItem = styled(Grid)(({theme}) => ({
    minWidth: '350px'

}));
const StyledTable = styled('table')(({theme}) => ({
    borderSpacing: 0,
    border: '1px solid',
    width: '100%'
}));
const StyledTr = styled('tr')(({theme}) => ({
    border: '1px solid'
}));
const StyledTd = styled('td')(({theme}) => ({
    margin: 0,
    padding: '0.5rem',
    borderBottom: '1px solid',
    borderRight: '1px solid',

    '&:lastChild': {
        borderRight: 0
    }
}));

function location(clazz: any) {
    if (clazz['w3w'] && clazz['w3w'].length > 0)
        return (<a href={"https://w3w.co/" + clazz.w3w} target="_blank" rel="noreferrer"> {clazz.location}</a>)
    return clazz.location
}

function Classes() {
    // @ts-ignore
    return (
        <StyledFullWidthDiv>
            <StyledGrid container spacing={3}>
                <StyledGridItem item xs={6} sm={4} md={2} key='0' sx={{mx: "auto"}}>
                    <StyledCard sx={{height: 150}}>
                        <CardHeader title='Classes'/>
                        <CardContent>
                            First trial lesson is free, £5 pay on the door after the first lesson.
                        </CardContent>
                    </StyledCard>
                </StyledGridItem>
            </StyledGrid>

            <StyledGrid container spacing={3}>
                {ClassList.map(tile => (
                    <StyledGridItem item xs={6} sm={4} md={2} key={tile.id} sx={{mx: "auto"}}>
                        <StyledCard>
                            <CardHeader title={tile.day}/>
                            <CardContent>
                                <StyledTable>
                                    {tile.classes.map(c => (
                                        <StyledTr>
                                            <StyledTd>{c.time}</StyledTd>
                                            <StyledTd>{c.type}</StyledTd>
                                            <StyledTd>{location(c)}</StyledTd>
                                        </StyledTr>
                                    ))}
                                </StyledTable>
                            </CardContent>

                        </StyledCard>
                    </StyledGridItem>
                ))}
            </StyledGrid>
        </StyledFullWidthDiv>

    );

}

export default Classes;
