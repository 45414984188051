import {Paper, styled} from "@mui/material";
import Markdown from "./Markdown";


const StyledFullWidthDiv = styled('div')(({theme}) => ({
    width: '100%',
    backgroundColor: theme.palette.background.default,
    color: "whitesmoke"
}));
const StyledPaper = styled(Paper)(({theme}) => ({
    backgroundColor: theme.palette.background.default,
    flex: 1,
    padding: theme.spacing(3),
    color: 'whitesmoke',
    align: 'center',
    marginTop: 'auto'
}));

function Syllabus() {
    return (
        <StyledFullWidthDiv>
            <StyledPaper>
                <Markdown url={process.env.REACT_APP_CONTENT_BASE_URL + "syllabus.md"}/>
            </StyledPaper>
        </StyledFullWidthDiv>
    );
}

export default Syllabus;
