import {Paper, styled} from "@mui/material";
import Link from "@mui/material/Link";
import {Auth} from "aws-amplify";

const StyledFullWidthDiv = styled('div')(({theme}) => ({
    width: '100%',
    backgroundColor: theme.palette.background.default,
    color: "whitesmoke"
}));
const StyledPaper = styled(Paper)(({theme}) => ({
    backgroundColor: theme.palette.background.default,
    flex: 1,
    padding: theme.spacing(3),
    color: 'whitesmoke',
    align: 'center',
    marginTop: 'auto',
}));

function Logout() {
    Auth.signOut();
    return (
        <StyledFullWidthDiv>
            <StyledPaper>
                You are now logged out.
            </StyledPaper>
            <StyledPaper>
                <Link href='/'>Home</Link>

            </StyledPaper>
        </StyledFullWidthDiv>
    );
}

export default Logout;
