import Footer from "../components/Footer";
import {styled, Typography} from "@mui/material";


const StyledFullWidthDiv = styled('div')(({theme}) => ({
    width: '100%'
}));
const StyledDiv = styled('div')(({theme}) => ({
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
}));

function NoPageFound() {
    return (
        <StyledFullWidthDiv>
            <StyledDiv>
                <Typography variant='h6'>Nope, nowt here, summat's gone wrong</Typography>
            </StyledDiv>
            <Footer/>
        </StyledFullWidthDiv>
    );
}

export default NoPageFound;
