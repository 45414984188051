import {Paper, styled} from "@mui/material";
import Markdown from "./Markdown";
import './GojuRyuHistory.css';

const StyledFullWidthDiv = styled('div')(({theme}) => ({
    width: '100%',
    backgroundColor: theme.palette.background.default,
    color: "whitesmoke"
}));
const StyledPaper = styled(Paper)(({theme}) => ({
    backgroundColor: theme.palette.background.default,
    flex: 1,
    padding: theme.spacing(3),
    color: 'whitesmoke',
    align: 'center',
    marginTop: 'auto',
}));

function GojuRyuHistory() {
    return (
        <StyledFullWidthDiv>
            <StyledPaper>
                <Markdown url={process.env.PUBLIC_URL + process.env.REACT_APP_CONTENT_BASE_URL + "history.md"}/>
            </StyledPaper>
        </StyledFullWidthDiv>
    );
}

export default GojuRyuHistory;
