import {styled, Typography} from "@mui/material";

const StyledDiv = styled('div')(({theme}) => ({
    width: `100%`,
    height: '28px',
    top: 'auto',
    position: 'fixed',
    bottom: 0,
    border: 0,
    color: 'whitesmoke',
    backgroundColor: theme.palette.background.paper,
}));
const StyledText = styled(Typography)(({theme}) => ({
    marginLeft: 0,
    marginTop: 2,
    fontSize: '14px',
    [theme.breakpoints.down('sm')]: {
        marginTop: 6,
        fontSize: '10px'
    }
}));
function Footer() {
    return (
        <StyledDiv>
            <StyledText align="center">
                © Copyright {new Date().getFullYear()} Blackrod and Bolton Academy of Goju Ryu Karate Do
            </StyledText>
        </StyledDiv>
    );
}


export default Footer;
