import * as React from 'react';
import {useEffect, useRef} from 'react';
import ReactMarkdown from 'markdown-to-jsx';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';

function MarkdownListItem(props: any) {
  return <Box component="li" sx={{mt: 1, typography: 'body1'}} {...props} />;
}

const options = {
  overrides: {
    h1: {
      component: Typography,
      props: {
        gutterBottom: true,
        variant: 'h4',
        component: 'h1',
      },
    },
    h2: {
      component: Typography,
      props: {gutterBottom: true, variant: 'h6', component: 'h2'},
    },
    h3: {
      component: Typography,
      props: {gutterBottom: true, variant: 'subtitle1'},
    },
    h4: {
      component: Typography,
      props: {
        gutterBottom: true,
        variant: 'caption',
        paragraph: true,
      },
    },
    p: {
      component: Typography,
      props: {paragraph: true},
    },
    a: {component: Link},
    li: {
      component: MarkdownListItem,
    },
  },
};

function useDidMount() {
  const didMountRef = useRef(true);

  useEffect(() => {
    didMountRef.current = false;
  }, []);
  return didMountRef.current;
};

export default function Markdown(props: any) {
  const didMount = useDidMount();
  const [content, setContent] = React.useState("");
  console.log("url: " + props.url);
  useEffect(() => {
    if (didMount) {
      fetch(props.url)
          .then(response => response.text())
          .then(data => {
            setContent(data);
            console.log("data: " + data);
          });
    }
  }, [content, didMount, props.url]);
  return <ReactMarkdown options={options}>
    {content}
  </ReactMarkdown>;
}
