// @ts-ignore
import * as videoReact from 'video-react';
import {useEffect, useState} from "react";
import {styled} from "@mui/material";
import {useParams} from "react-router-dom";
// import {ConsoleLogger as Logger} from '@aws-amplify/core'
import Storage from '@aws-amplify/storage'


// const logger = new Logger('VideoPlayer')

const StyledPlayer = styled(videoReact.Player)(({theme}) => ({
    display: "flex",
    height: "100%",
    width: "100%",
    margin: 'auto'

}));

function VideoPlayer() {
    const [url, setUrl] = useState<string>('');
    let {filename} = useParams<"filename">();
    let {shortname} = useParams<"shortname">();

    const getUrl = async () => {
        let s3Url = undefined;
        if (filename) {
            let path = "videos/" + shortname + "/" + filename;
            console.log("Finding url for: ", path)
            s3Url = await Storage.get(path, {level: 'public'})
            console.log("url:" + s3Url)
        }
        if (!s3Url) {
            throw new Error("url not found");
        }
        setUrl(s3Url);
    }

    useEffect(() => {
        getUrl();
    });

    let {height} = useParams<"height">();
    let {width} = useParams<"width">();

    const StyledDiv = styled('div')(({theme}) => ({
        display: "flex",
        height: height + "px",
        width: width + "px",
        backgroundColor: theme.palette.background.default,
    }));
    return (
        <StyledDiv>
            <StyledPlayer
                autoPlay={true}
                fluid={false}
                width={width}
                height={height}
            >
                <source
                    src={url}
                    type="video/mp4"

                />
                <videoReact.ControlBar>
                    <videoReact.ReplayControl seconds={5} order={1.1}/>
                    <videoReact.ForwardControl seconds={5} order={1.2}/>
                    <videoReact.CurrentTimeDisplay order={4.1}/>
                    <videoReact.TimeDivider order={4.2}/>
                    <videoReact.PlaybackRateMenuButton rates={[2, 1, 0.75, 0.5, 0.25, 0.1]} order={7.1}/>
                </videoReact.ControlBar>
            </StyledPlayer>
        </StyledDiv>
    );
}

export default VideoPlayer;
