import {AppBar, IconButton, MenuItem, styled, Toolbar, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Drawer from "@mui/material/Drawer";
import {useState} from "react";
import {useNavigate} from "react-router-dom";

interface HeaderProps {
    sections: ReadonlyArray<{
        title: string;
        url: string;
        newWindow: boolean;
    }>;
    title: string;
}

const StyledAppBar = styled(AppBar)(({theme}) => ({
    width: `calc(100% px)`,
    marginLeft: 0,
    marginTop: 0,
    paddingLeft: 0,
    paddingRight: 0,
    backgroundColor: theme.palette.background.paper,

    // '@media all': {
    //     minHeight: 64,
    // },
}));

const StyledToolbar = styled(Toolbar)(({theme}) => ({
    verticalAlign: 'top',
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    '@media all': {
        minHeight: 45,
    },

}));

const StyledMenubar = styled(Toolbar)(({theme}) => ({
    visible: true,
    display: 'flex',
    flex: "auto",
    alignItems: 'flex-start',
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    [theme.breakpoints.down('sm')]: {
        visible: false,
        display: 'none'
    },
    '@media all': {
        minHeight: 45,
    },
}));
const StyledHamburger = styled(IconButton)(({theme}) => ({
    visible: false,
    height: '45px',
    [theme.breakpoints.up('xs')]: {
        visible: true,
    }
}));
const StyledMenuLink = styled(Typography)(({theme}) => ({}));


function Header(props: HeaderProps) {
    let navigate = useNavigate();
    const {sections, title} = props;

    const [open, setOpen] = useState(false);

    return (<StyledAppBar position="static">
            <Container maxWidth={false} disableGutters={true}>
                <StyledToolbar>
                    <StyledHamburger edge="end" color="inherit"
                                     onClick={(e) => setOpen(true)}
                                     sx={{mr: 1, display: {xs: 'block', sm: 'none',},}}>
                        <MenuIcon/>
                    </StyledHamburger>
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{flexGrow: 1, verticalAlign: 'top', textAlign: 'center'}}
                    >
                        {title}
                    </Typography>

                    {/* The outside of the drawer */}
                    <Drawer
                        anchor="left" //from which side the drawer slides in
                        variant="temporary" //if and how easily the drawer can be closed
                        open={open} //if open is true, drawer is shown
                        onClose={(e) => setOpen(false)} //function that is called when the drawer should close
                    >
                        <Box>
                            {sections.map((section) => (
                                <MenuItem key={section.title}
                                          onClick={() => {
                                              setOpen(false);
                                              if (section.newWindow) {
                                                  window.open(section.url,'_blank', 'rel=noopener noreferrer');
                                              } else {
                                                  navigate(section.url);
                                              }
                                          }}>
                                    <StyledMenuLink>{section.title}</StyledMenuLink>
                                </MenuItem>

                            ))}

                        </Box>
                    </Drawer>
                </StyledToolbar>
                <StyledMenubar
                    variant="dense"
                    sx={{overflowX: 'auto'}}
                >
                    {sections.map((section) => (
                        <MenuItem onClick={() => {
                            if (section.newWindow) {
                                window.open(section.url,'_blank', 'rel=noopener noreferrer');
                            } else {
                                navigate(section.url);
                            }
                        }}
                            key={section.title}>
                            <StyledMenuLink>{section.title}</StyledMenuLink>
                        </MenuItem>

                    ))}
                </StyledMenubar>
            </Container>
        </StyledAppBar>
    );
}

export default Header;
