import {useNavigate} from "react-router-dom";
import {findVideoListByShortname} from "../common/videoCategories";
import {Card, CardHeader, CardMedia, Grid, styled} from "@mui/material";
import React, {useRef} from "react";

const StyledDiv = styled('div')(({theme}) => ({
    display: "flex",
    flexWrap: "wrap",
    height: "100%",
    backgroundColor: theme.palette.background.paper,

}));

const StyledCard = styled(Card)(({theme}) => ({
    width: 250,
    height: 250,
    backgroundColor: '#2d2f32',
    margin: 'auto',
    textAlign: 'center',
}));
const StyledGrid = styled(Grid)(({theme}) => ({
    marginLeft: 'auto',
    marginRight: 'auto',

}));
const StyledGridItem = styled(Grid)(({theme}) => ({
    minWidth: '270px',
    maxWidth: '100%',
    padding: '24px'
}));
// const StyledCardMedia = styled(CardMedia)(({theme}) => ({
//     height: 250, paddingTop: '0'
// }));
const StyledCardMedia = styled(CardMedia)(({theme}) => ({
    height: 190, paddingTop: '0', width: 250,
    backgroundSize: 'contain'
}));

function calcUrl(shortname: string, filename: string) {
    const picFilename = encodeURI(filename.substring(0, filename.lastIndexOf(".")) + ".jpg");
    return process.env.REACT_APP_VIDEO_BASE_URL + shortname + "/" + picFilename;
}

function VideoCategoryList(props: { shortname: string }) {
    const navigate = useNavigate();
    const videos = findVideoListByShortname(props.shortname);
    const containerRef = useRef() as React.MutableRefObject<HTMLDivElement>;

    function onClickHandler(targetRef: React.MutableRefObject<HTMLDivElement>, shortname: String, filename: String) {
        const width = window.innerWidth;
        const height = window.innerHeight - (128 + 28 + 20);
        navigate('/video-player/' + shortname + "/" + filename + "/" + width + "/" + height);
    }

    return (
        <StyledDiv ref={containerRef}>
            <StyledGrid container spacing={3}>
                {videos.map(tile => (
                    <StyledGridItem item xs={12} sm={6} md={4} lg={2} key={tile.id}
                                    onClick={() => onClickHandler(containerRef, props.shortname, tile.filename)}>
                        <StyledCard>
                            <CardHeader title={tile.description}/>
                            <StyledCardMedia
                                image={calcUrl(props.shortname, tile.filename)}
                                title={tile.filename}
                            />
                        </StyledCard>
                    </StyledGridItem>
                ))}
            </StyledGrid>
        </StyledDiv>
    );
}


export default VideoCategoryList;
